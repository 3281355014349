<template>
  <div class="wrap">
    <myHeader />
    <div class="container">
      <p><span style="font-size:20px">经营者特许经营许可证信息</span></p>
      <p><span style="font-size:16px">根据国家法律法规规定，经营者自主上传并公示特许经营许可证照信息如下：</span></p>
      <img src="@/assets/img/07-底部信息/营业执照.png">
      <img src="@/assets/img/07-底部信息/经营许可证.png">
    </div>
    <homeFooter />
  </div>
</template>

<script>
  import MyHeader from '@/components/MyHeader';
  import HomeFooter from '@/components/HomeFooter';

  export default {
    name: 'dachengBusinessLicense',
    components:{
      MyHeader,
      HomeFooter
    }
  }
</script>

<style lang="less" scoped>
  .wrap{
    min-width: 1200px;
  }
  .container{
    text-align: center;
    min-height: calc(100vh - 220px);
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
    p{
      padding: 10px;
    }
    img{
      width: 1000px;
    }
  }
</style>